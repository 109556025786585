window.NewsletterService = function (_settings) {
  "use strict";
  const _self = this;
  let settings;
  this.settings = _settings;

  this.getMeta = function (metaName) {
    const metas = document.getElementsByTagName('meta');
    for (let i = 0; i < metas.length; i++) {
      if (metas[i].getAttribute('name') === metaName) {
        return metas[i].getAttribute('content');
      }
    }
    return '';
  }

  this.fetchData = function (entryPoint ,method, data, successCallback, failedCallback) {
    const fullurl = _self.settings.endPointCart + entryPoint;
    const xhrOptions = {
      method: method,
      headers: {
        "X-CSRF-Token": _self.getMeta('csrf-token')
      },
      mode: 'cors',
      cache: 'default'
    };
    if (data !== undefined) {
      xhrOptions.body = data;
      xhrOptions.headers['Accept'] = 'application/json';
      xhrOptions.headers['Content-Type'] = 'application/json';

    }

    return fetch(fullurl,xhrOptions)
      .then(function(response) {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(successCallback)
      .catch(failedCallback);

  }


  return {
    sendMail : function (data ,successCallback, failedCallback) {
      return _self.fetchData('/newsletterServe/sendMail', 'POST', JSON.stringify( data )  , successCallback, failedCallback );
    },
    unsubscribe : function (data ,successCallback, failedCallback) {
      return _self.fetchData('/newsletterServe/unsubscribe', 'POST', JSON.stringify( data )  , successCallback, failedCallback );
    },
  }
}

window.newsletter = new window.NewsletterService({'endPointCart':`${baseUrl}/service`});
